import { useEffect, useState } from 'react'

import { ClientSignUpAnalytics } from 'components/client-sign-up/analytics'
import s from './styles.module.css'
import { Button } from 'components/design-system/button'
import Router from 'next/router'

interface ReengagementFormProps {
  onRetry: (data?: any) => void
  onCancelReengagement: () => void
  onReengagementLinkClick: () => void
  reengagementLink?: string
  signInTokenMutation: any
}

const ReengagementForm = ({
  onRetry,
  onCancelReengagement,
  onReengagementLinkClick,
  reengagementLink,
  signInTokenMutation,
}: ReengagementFormProps) => {
  const [hasRetried, setHasRetried] = useState(false)
  const [token, setToken] = useState('')
  const [authTokenError, setAuthTokenError] = useState('')
  const handleTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToken(e.target.value)
  }

  const handleSubmit = async () => {
    const {
      data: {
        signInToken: { sessionToken },
      },
    } = await signInTokenMutation({ variables: { token } })
    console.log('sessionToken', sessionToken)

    if (!sessionToken) {
      setAuthTokenError('The token you entered is expired or invalid')
      return
    }
    Router.push(reengagementLink)
    onReengagementLinkClick()
  }

  const handleRetry = () => {
    if (hasRetried) return

    ClientSignUpAnalytics.onResendReengagementText()
    onRetry(!!reengagementLink)
    setHasRetried(true)
  }

  const handleCancelReengagement = () => {
    ClientSignUpAnalytics.onCancelReengagement()
    onCancelReengagement()
  }

  useEffect(() => {
    if (hasRetried) {
      setTimeout(() => {
        setHasRetried(false)
      }, 3000)
    }
  }, [hasRetried])

  return (
    <div>
      <h2 className={s.reengagementHeader}>
        {reengagementLink
          ? 'We just sent you a text!'
          : 'Welcome Back to Kickoff!'}
      </h2>
      <div className={s.reengagementBody}>
        <p className={s.reengagementText}>
          {reengagementLink
            ? 'Enter the code below to see your coach matches.'
            : 'Thanks! We just texted you a link to finish signing up securely. Check your text messages.'}
        </p>
        {reengagementLink && (
          <div className={s.inputContainer}>
            <input
              className={s.input}
              onChange={handleTokenChange}
              placeholder="Enter code"
            />

            {authTokenError && (
              <span className={s.authTokenError}>{authTokenError}</span>
            )}
          </div>
        )}
        {!hasRetried && (
          <div className={s.buttons}>
            {reengagementLink && (
              <Button
                className={s.retryButtonB}
                size="small"
                onClick={handleSubmit}>
                Submit
              </Button>
            )}
            <Button
              className={s.retryButtonB}
              variant="text"
              size="small"
              onClick={handleRetry}>
              Resend text
            </Button>
          </div>
        )}
        {hasRetried && <span className={s.retryConfirmation}>Sent!</span>}
        {onCancelReengagement && (
          <button
            className={s.cancelReengagementButton}
            onClick={handleCancelReengagement}>
            I entered my phone number incorrectly
          </button>
        )}
      </div>
    </div>
  )
}

export default ReengagementForm
